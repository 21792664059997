import React from "react";

export const Loader = () => {
	return (
		<>
			<div className="section loader">
				<div className="container">
					<h2>секунду, шукаємо ідеальний метч</h2>
					<div className="loader__wrap">
						<div className="preloader">
							<div className="circle"></div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
