import React, { useState } from "react";

const ButtonGroup = ({ title, options, setFuntion }) => {
	const [activeItem, setActiveItem] = useState(null);

	const handleClick = (type) => {
		if (activeItem === type) {
			setFuntion(null);
			setActiveItem(null);
		} else {
			setFuntion(type);
			setActiveItem(type);
		}
	};

	return (
		<div className="filters-section">
			<h3>{title}</h3>
			<div className="filters-buttons">
				{options.map((type) => (
					<button key={type} onClick={(e) => handleClick(type)} className={activeItem === type ? "active" : ""}>
						{type}
					</button>
				))}
			</div>
		</div>
	);
};

export default ButtonGroup;
