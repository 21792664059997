import React from "react";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";

const marks = {
	"-1": "",
	0: "",
	1: "",
	2: "",
	5: "",
	4: "",
	5: "",
};

export const InputGroup = ({ options, setFeelings, feelings, wineColor }) => {
	return (
		<>
			{options.map((feeling) => {
				const shouldRender = !feeling.condition || feeling.condition.includes(wineColor);
				if (!shouldRender) {
					return null;
				}

				return (
					<div className="filters-section filters-input" key={feeling.name}>
						<h3>{feeling.label}</h3>
						<label>
							<Slider
								dots
								step={1}
								marks={marks}
								min={-1}
								defaultValue={[-1, 0]}
								onChange={(e) => setFeelings({ ...feelings, [feeling.name]: parseInt(e) })}
								max={5}
								allowCross={false}
								pushable
								draggableTrack
								dotStyle={{ borderColor: "#D24A4A" }}
								activeDotStyle={{ borderColor: "#D24A4A" }}
							/>
							<div className="filters-input-row">
								<div className="default">
									не має <br /> значення
									<div className="min">{feeling.minLabel}</div>
								</div>
								<div className="max">{feeling.maxLabel}</div>
							</div>
						</label>
					</div>
				);
			})}
		</>
	);
};
