import React, { useEffect, useState, useCallback, useMemo } from "react";
import { Start } from "./components/Start";
import axios from "axios";
import "./assets/styles/style.scss";
import Header from "./components/Header";
import { MainFilters } from "./components/MainFilters";
import { DescriptorFilters } from "./components/DescriptorFilters";
import { Loader } from "./components/Loader";
import { Products } from "./components/Products";

const filterOptions = {
	wineTypes: ["тихе", "ігристе"],
	wineColors: ["черв.", "біле", "розе", "оранж", "кріпл."],
	feelings: [
		{ name: "weight", label: "як має відчуватись?", minLabel: "легке", maxLabel: "важке" },
		{ name: "acidity", minLabel: "не кисле", maxLabel: "кисле" },
		{ name: "viscosity", minLabel: "не в'язке", maxLabel: "в'язке", condition: ["черв.", "оранж"] },
		{ name: "craziness", minLabel: "не дуже craziness", maxLabel: "craziness" },
	],
};

function App() {
	const [step, setStep] = useState(0);
	const [products, setProducts] = useState([]);
	const [filteredProducts, setFilteredProducts] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [wineType, setWineType] = useState("");
	const [wineColor, setWineColor] = useState("");
	const [descriptor, setDescriptor] = useState([]);
	const [selectedDescriptors, setSelectedDescriptors] = useState([]);

	const [feelings, setFeelings] = useState({
		weight: -1,
		acidity: -1,
		viscosity: -1,
		craziness: -1,
	});

	useEffect(() => {
		async function fetchData() {
			try {
				if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
					const { data } = await axios.get(
						"https://admin.wise.in.ua/wp-json/wc/v3/products?per_page=100&consumer_key=ck_849fa69df879d51478325dc9950db4eecd0240d1&consumer_secret=cs_2699ce92710b080ca70c8b15366f0be0016efa30"
					);

					const descriptorsData = await axios.get(
						"https://admin.wise.in.ua/wp-json/wc/v3/products/tags?per_page=100&consumer_key=ck_849fa69df879d51478325dc9950db4eecd0240d1&consumer_secret=cs_2699ce92710b080ca70c8b15366f0be0016efa30"
					);
					setDescriptor(descriptorsData.data);

					setProducts(data);
					setIsLoading(false);
				} else {
					const { data } = await axios.get(
						window.location.origin +
							"/wp-json/wc/v3/products?per_page=100&consumer_key=ck_c194e193c2092b6f4ddc4e8fc9a80ed2cf25c86e&consumer_secret=cs_3de1c6d6a6400a332f4e821061022855bde5c7a1"
					);
					const descriptorsData = await axios.get(
						window.location.origin +
							"/wp-json/wc/v3/products/tags?per_page=100&consumer_key=ck_c194e193c2092b6f4ddc4e8fc9a80ed2cf25c86e&consumer_secret=cs_3de1c6d6a6400a332f4e821061022855bde5c7a1"
					);
					setDescriptor(descriptorsData.data);

					setProducts(data);
					setIsLoading(false);
				}
			} catch (e) {
				alert("Сталася помилка під час обробки даних(");
				console.log(e);
			}
		}

		fetchData();
	}, []);

	const processedDescriptors = useMemo(() => {
		return descriptor.map((item) => ({
			name: item.name,
			color: item.acf.descriptor_color,
			background: item.acf.descriptor_bg,
			condition: item.acf.condition,
		}));
	}, [descriptor]);

	const filteredDescriptors = useMemo(() => {
		return processedDescriptors.filter((item) => !item.condition || item.condition.includes(wineColor));
	}, [wineColor, processedDescriptors]);

	const nextStep = () => {
		setStep(step + 1);
	};

	const handleFiltersSubmit = useCallback(() => {
		setIsLoading(true);

		const filtered = products.filter((product) => {
			const meta = product.meta_data.reduce((acc, meta) => {
				acc[meta.key] = meta.value;
				return acc;
			}, {});
			const descriptors = product.tags.reduce((acc, tag) => {
				acc[tag.name] = tag.name;
				return acc;
			}, {});

			const areDescriptorsValid = selectedDescriptors.length === 0 || selectedDescriptors.every((descriptor) => descriptors.hasOwnProperty(descriptor));

			const isWithinRange = (value, target) => target === -1 || (value >= target - 2 && value <= target + 2);

			const weightCheck = isWithinRange(meta.weight, feelings.weight);
			const acidityCheck = isWithinRange(meta.acidity, feelings.acidity);
			const viscosityCheck = isWithinRange(meta.viscosity, feelings.viscosity);
			const crazinessCheck = isWithinRange(meta.craziness, feelings.craziness);

			const wineTypeCheck = !wineType || meta.wineType === wineType;
			const wineColorCheck = !wineColor || meta.wineColor === wineColor;

			return wineTypeCheck && wineColorCheck && weightCheck && acidityCheck && viscosityCheck && crazinessCheck && areDescriptorsValid;
		});

		setFilteredProducts(filtered);
		setIsLoading(false);
		setStep(3);
	}, [products, wineType, wineColor, feelings, selectedDescriptors, step]);

	const clearFilters = () => {
		setStep(1);
		setSelectedDescriptors([]);
		setFilteredProducts([]);
		setWineType("");
		setWineColor("");
	};

	return (
		<div className="App">
			<Header />
			{step === 0 && <Start onNext={nextStep} />}
			{step === 1 && (
				<MainFilters
					filterOptions={filterOptions}
					setWineColor={setWineColor}
					setWineType={setWineType}
					handleSubmit={nextStep}
					setFeelings={setFeelings}
					feelings={feelings}
					wineType={wineType}
					wineColor={wineColor}
					onNext={nextStep}
				/>
			)}
			{step === 2 && (
				<DescriptorFilters
					isLoading={isLoading}
					descriptors={filteredDescriptors}
					selectedDescriptors={selectedDescriptors}
					setSelectedDescriptors={setSelectedDescriptors}
					onNext={handleFiltersSubmit}
					wineType={wineType}
					wineColor={wineColor}
					feelings={feelings}
					products={products}
				/>
			)}
			{step === 3 &&
				(isLoading ? (
					<Loader />
				) : (
					<Products
						products={filteredProducts}
						abort={clearFilters}
						processedDescriptors={processedDescriptors}
						feelings={feelings}
						selectedDescriptors={selectedDescriptors}
						wineType={wineType}
						wineColor={wineColor}
					/>
				))}
		</div>
	);
}

export default App;
