import React from "react";

let imageUrl;

if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
	imageUrl = "../assets/img/front/img.svg";
} else {
	imageUrl = window.location.origin + "/wp-content/themes/wise-admin-panel/build/assets/img/front/img.svg";
}

export const Start = ({ onNext }) => {
	return (
		<section className="banner" id="banner">
			<div className="container">
				<div className="banner__img">
					<img src={imageUrl} alt="hello!" />
				</div>
				<div className="banner__info">
					<h1>привіт! </h1>
					<p>
						це вайз — помічник по вибору вина. ми врахуємо твої побажання та порадимо найліпше вино або інший напій під твій настрій, страву або
						подію. такий собі карманний сомельє!
					</p>
				</div>
			</div>
			<button className="bttn banner__bttn" onClick={onNext}>
				продовжити
			</button>
		</section>
	);
};
