import React, { useState, useEffect, useRef } from "react";

let baseImageUrl;

if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
	baseImageUrl = "../assets/img";
} else {
	baseImageUrl = window.location.origin + "/wp-content/themes/wise-admin-panel/build/assets/img";
}

export const Products = ({ products, abort, processedDescriptors, feelings, selectedDescriptors, wineType, wineColor }) => {
	const [randomImage, setRandomImage] = useState(null);
	const [activeProductId, setActiveProductId] = useState(null);
	const productsWrapRef = useRef(null); // Reference to products__wrap

	const images = [
		{ url: `${baseImageUrl}/empty/1.svg`, className: "center" },
		{ url: `${baseImageUrl}/empty/2.svg`, className: "right" },
		{ url: `${baseImageUrl}/empty/3.svg`, className: "center center-2" },
	];

	function getRandomImage() {
		const randomIndex = Math.floor(Math.random() * images.length);
		return images[randomIndex];
	}

	const reset = () => {
		abort();
		document.querySelector(".header").classList.remove("header--scrolled");
	};

	useEffect(() => {
		const image = getRandomImage();
		setRandomImage(image);
	}, []);

	const toggleProduct = (productId) => {
		setActiveProductId(activeProductId === productId ? null : productId);
	};

	const calculateMatchPercentage = (product) => {
		const meta = product.meta_data.reduce((acc, meta) => {
			acc[meta.key] = meta.value;
			return acc;
		}, {});
		const descriptors = product.tags.reduce((acc, tag) => {
			acc[tag.name] = tag.name;
			return acc;
		}, {});

		const calculateCloseness = (value, target) => {
			if (target === -1) return 1;
			const distance = Math.abs(value - target);
			return Math.max(0, 1 - distance / 2);
		};

		const checks = [
			{
				check: selectedDescriptors.length === 0 || selectedDescriptors.every((descriptor) => descriptors.hasOwnProperty(descriptor)),
				weight: 1,
				closeness: 1,
			},
			{ check: true, weight: 1, closeness: calculateCloseness(meta.weight, feelings.weight) },
			{ check: true, weight: 1, closeness: calculateCloseness(meta.acidity, feelings.acidity) },
			{ check: true, weight: 1, closeness: calculateCloseness(meta.viscosity, feelings.viscosity) },
			{ check: true, weight: 1, closeness: calculateCloseness(meta.craziness, feelings.craziness) },
			{ check: true, weight: 1, closeness: wineType ? (meta.wineType === wineType ? 1 : 0) : 1 },
			{ check: true, weight: 1, closeness: wineColor ? (meta.wineColor === wineColor ? 1 : 0) : 1 },
		];

		const totalWeight = checks.reduce((sum, check) => sum + check.weight, 0);
		const passedWeight = checks.reduce((sum, check) => sum + check.weight * check.closeness, 0);

		return Math.round((passedWeight / totalWeight) * 100);
	};

	useEffect(() => {
		const handleScroll = () => {
			if (productsWrapRef.current) {
				if (productsWrapRef.current.scrollTop > 100) {
					document.querySelector(".header").classList.add("header--scrolled");
				} else {
					document.querySelector(".header").classList.remove("header--scrolled");
				}
			}
		};

		const productsWrap = productsWrapRef.current;
		if (productsWrap) {
			productsWrap.addEventListener("scroll", handleScroll);
		}

		return () => {
			if (productsWrap) {
				productsWrap.removeEventListener("scroll", handleScroll);
			}
		};
	}, [productsWrapRef.current]);

	return (
		<div className="products section">
			<div className="container">
				{products && products.length > 0 ? (
					<>
						<div className="products__wrap" ref={productsWrapRef}>
							{products.map((product) => {
								const countryEl = product.meta_data.reduce((acc, meta) => {
									acc[meta.key] = meta.value;
									return acc;
								}, {});

								const country = countryEl.country ? countryEl.country : "";

								const imageSrc = product.images && product.images.length > 0 ? product.images[0].src : "";

								const cityAttribute = product.attributes.find((attr) => attr.name === "Місце" || attr.slug === "Місце");
								const city = cityAttribute ? cityAttribute.options[0] : "";

								const priceAttributes = product.attributes.filter((attr) => {
									return attr.name !== "Країна" && attr.slug !== "Країна" && attr.name !== "Місце" && attr.slug !== "Місце";
								});

								const prices = priceAttributes.map((attr) => {
									return {
										size: attr.name,
										price: attr.options[0],
									};
								});

								const matchPercentage = calculateMatchPercentage(product);

								return (
									<div className="product__item" key={product.id}>
										<div className="product__top" onClick={() => toggleProduct(product.id)}>
											<div className="product__item-img">
												<img src={imageSrc} alt={product.name} />
											</div>
											<div className="product__item-info">
												<div className="product__item-head">
													{country && <div className="product__item-country">{country}</div>}
													<div className="product__item-match">{matchPercentage}%</div>
												</div>
												<h4 className="product__item-title">{product.name}</h4>
												<div className="product__item-category">{city}</div>
												{product.tags && (
													<div className="product__item-descriptors">
														{product.tags.map((descriptor) => {
															const processedDescriptor = processedDescriptors.find((item) => item.name === descriptor.name);

															return (
																<p
																	key={descriptor.name}
																	style={{
																		backgroundColor: processedDescriptor ? processedDescriptor.background : "#ffffff",
																		color: processedDescriptor ? processedDescriptor.color : "#000000",
																	}}
																>
																	{descriptor.name}
																</p>
															);
														})}
													</div>
												)}
												{prices && (
													<div className="product__item-prices">
														{prices.map((price) => (
															<div className="product__item-price" key={price.size}>
																<div className="size">{price.size}</div>
																<span></span>
																<div className="price">{price.price}</div>
															</div>
														))}
													</div>
												)}
											</div>
										</div>
										{activeProductId === product.id && (
											<div className="product__bottom">
												<div className="product__bottom-title">деталі</div>
												<div className="product__item-content">
													<div dangerouslySetInnerHTML={{ __html: product.description }} />
												</div>
											</div>
										)}
									</div>
								);
							})}
							<div className="product__bttn">
								<button className="bttn" onClick={() => reset()}>
									спробувати ще
								</button>
							</div>
						</div>
					</>
				) : (
					<div className="products__empty">
						<div className="products__empty-head">
							<h2>халепа!</h2>
							<p>
								ми не знайшли пляшку, яка б ідеально підійшла вашим очікуванням. але тут на допомогу приходять люди! будь ласка, зверніться до
								сомельє або офіціанту{" "}
							</p>
						</div>
						<div className="products__empty-bottom">
							{randomImage && (
								<div className={`products__empty-img ${randomImage.className}`}>
									<img src={randomImage.url} alt="Empty" />
								</div>
							)}
							<div className="product__empty-bttn">
								<button className="bttn" onClick={abort}>
									спробувати ще
								</button>
							</div>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};
