import React from "react";

let imageUrl;

if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
	imageUrl = "../assets/img/general/logo.svg";
} else {
	imageUrl = window.location.origin + "/wp-content/themes/wise-admin-panel/build/assets/img/general/logo.svg";
}

const Header = (props) => {
	return (
		<>
			<header className="header autoHide">
				<div className="container">
					<a onClick={() => window.location.reload()} className="header__logo">
						<img src={imageUrl} alt="logo" />
					</a>
				</div>
			</header>
		</>
	);
};

export default Header;
