import React, { useRef, useState, useEffect } from "react";
import * as d3 from "d3";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Loader } from "./Loader";

export const DescriptorFilters = ({ isLoading, descriptors, selectedDescriptors, setSelectedDescriptors, onNext, wineType, wineColor, feelings, products }) => {
	const svgRef = useRef();
	const refContainer = useRef();
	const circlesRef = useRef({});
	const [buttonClicked, setButtonClicked] = useState(0);
	const [dimensions, setDimensions] = useState({
		width: 0,
		height: 0,
	});

	const updateDimensions = () => {
		if (refContainer.current) {
			setDimensions({
				width: refContainer.current.offsetWidth,
				height: refContainer.current.offsetHeight,
			});
		}
	};

	const [tempSelectedElements, setTempSelectedElements] = useState([]);

	const handleCircleClick = (d) => {
		const elements = document.querySelectorAll(`[data-name="${d.name}"]`);

		setTempSelectedElements((prevSelected) => {
			let newSelectedElements = [...prevSelected];

			if (newSelectedElements.includes(d.name)) {
				elements.forEach((element) => element.classList.remove("selected"));
				newSelectedElements = newSelectedElements.filter((name) => name !== d.name);
			} else {
				if (newSelectedElements.length < 5) {
					elements.forEach((element) => element.classList.add("selected"));
					newSelectedElements.push(d.name);
				} else {
					toast.info("це максимум, бо чим більше дескрипторів, тим складніше нам буде шукати", {
						position: "top-center",
						autoClose: 4994,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						theme: "colored",
					});
				}
			}

			return newSelectedElements;
		});
	};

	const handleSubmit = () => {
		setSelectedDescriptors(tempSelectedElements);
		setButtonClicked(buttonClicked + 1);
	};

	useEffect(() => {
		updateDimensions();
		// window.addEventListener("resize", updateDimensions);
		// return () => window.removeEventListener("resize", updateDimensions);
	}, []);

	useEffect(() => {
		if (buttonClicked > 0) {
			onNext();
		}
	}, [selectedDescriptors, buttonClicked]);

	function clamp(value, min, max) {
		return Math.max(min, Math.min(max, value));
	}

	useEffect(() => {
		if (dimensions.width === 0 || dimensions.height === 0 || descriptors.length === 0) return;

		const data = {
			name: "layout",
			children: descriptors.map((descriptor) => ({
				name: descriptor.name,
				size: 1000,
				class: descriptor.class,
				color: descriptor.color, // Додано для передачі кольору тексту
				background: descriptor.background, // Додано для передачі кольору фону
			})),
		};

		const margin = {
			top: 0,
			right: 15,
			bottom: 0,
			left: 15,
		};
		const width = dimensions.width - 40 - margin.left - margin.right;
		const height = dimensions.height - 40 - margin.top - margin.bottom;

		const n = data.children.length;
		const m = 1;
		const padding = 6;
		const radius = d3.scaleSqrt().range([0, 12]);
		const color = d3.scaleOrdinal(d3.schemeCategory10).domain(d3.range(m));
		const x = d3.scalePoint().domain(d3.range(m)).range([0, width]).padding(1);

		const nodes = data.children.map((d) => ({
			radius: getTextWidth(d.name, "16px sans-serif") / 2 + 10, // Динамічно обчислюємо радіус
			color: d.color,
			background: d.background,
			name: d.name,
			class: d.class,
			x: Math.random() * width,
			y: Math.random() * height,
		}));

		const svg = d3
			.select(svgRef.current)
			.attr("width", width + margin.left + margin.right)
			.attr("height", height + margin.top + margin.bottom)
			.append("g")
			.attr("transform", `translate(${margin.left},${margin.top})`);

		const circles = svg
			.selectAll("circle")
			.data(nodes)
			.enter()
			.append("circle")
			.attr("r", (d) => d.radius)
			.attr("class", (d) => d.class)
			.attr("data-name", (d) => d.name)
			.style("fill", (d) => d.background) // Встановлюємо фоновий колір кола
			.classed("selected", (d) => selectedDescriptors.includes(d.name))
			.each(function (d) {
				circlesRef.current[d.name] = this;
			})
			.call(
				d3.drag().on("drag", function (event, d) {
					d.x = clamp(event.x, d.radius, width - d.radius);
					d.y = clamp(event.y, d.radius, height - d.radius);
					d3.select(this).attr("cx", d.x).attr("cy", d.y);
				})
			)
			.on("click", function (event, d) {
				handleCircleClick(d);
			});
		const texts = svg
			.selectAll("text")
			.data(nodes)
			.enter()
			.append("text")
			.attr("dy", ".35em")
			.attr("data-name", (d) => d.name)
			.classed("selected", (d) => selectedDescriptors.includes(d.name))
			.style("text-anchor", "middle")
			.style("fill", (d) => d.color)
			.text((d) => d.name)
			.each(function (d) {
				const textElement = d3.select(this);
				const radius = circlesRef.current[d.name].r.baseVal.value;
				let fontSize = radius / 4;
				if (d.name.length >= 10) {
					fontSize = radius / 6;
				}
				textElement.style("font-size", `${fontSize}px`);

				while (textElement.node().getBBox().width > radius * 2) {
					fontSize -= 1;
					textElement.style("font-size", `${fontSize}px`);
				}
			});

		const force = d3
			.forceSimulation(nodes)
			.force("center", d3.forceCenter(width / 2, height / 2))
			.force("charge", d3.forceManyBody(height / 2).strength(-50))
			.force(
				"collide",
				d3
					.forceCollide()
					.radius((d) => d.radius + padding)
					.iterations(16)
			)
			.force("x", d3.forceX(width / 2).strength(0.1))
			.force("y", d3.forceY(1).strength(0.1))
			.alphaDecay(0)
			.on("tick", tick);

		function tick() {
			circles.attr("cx", (d) => d.x).attr("cy", (d) => d.y);
			texts.attr("x", (d) => d.x).attr("y", (d) => d.y);
		}

		return () => {
			svg.selectAll("*").remove();
		};
	}, [descriptors, dimensions, selectedDescriptors]);

	function getTextWidth(text, font) {
		const canvas = document.createElement("canvas");
		const context = canvas.getContext("2d");
		context.font = font;
		const metrics = context.measureText(text);
		return metrics.width;
	}

	return isLoading ? (
		<Loader />
	) : (
		<div className="section descriptor" ref={refContainer}>
			<ToastContainer
				position="top-center"
				autoClose={4994}
				limit={1}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick={true}
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
				theme="colored"
			/>
			<div className="container">
				<h2 className="tac">а чого хочеться в ароматі?</h2>
				<p>оберіть до 5 дескрипторів</p>
			</div>
			<div className="descriptor__wrap">
				<svg ref={svgRef}></svg>
			</div>
			<div className="descriptor__bttn">
				<button className="bttn" onClick={handleSubmit}>
					продовжити
				</button>
			</div>
		</div>
	);
};
