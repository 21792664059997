import React from "react";
import ButtonGroup from "./Filters/ButtonGroup";
import { InputGroup } from "./Filters/InputGroup";

export const MainFilters = ({ filterOptions, setWineColor, setWineType, onNext, setFeelings, feelings, wineType, wineColor }) => {
	return (
		<div className="section filters">
			<div className="container">
				<div className="filters-group">
					<ButtonGroup title={"що налити?"} options={filterOptions.wineTypes} setFuntion={setWineType} />
					<ButtonGroup title={"якого кольору?"} options={filterOptions.wineColors} setFuntion={setWineColor} />
					<InputGroup options={filterOptions.feelings} setFeelings={setFeelings} feelings={feelings} wineColor={wineColor} />
				</div>
			</div>
			<div className="next-step-wrap">
				<button className="bttn " onClick={onNext}>
					продовжити
				</button>
			</div>
		</div>
	);
};
